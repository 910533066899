<template>
  <div class=" container-layout-custom justify-center full-height ak-text light-theme-text-default" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row q-mb-xl justify-between items-end">
        <div class="text-h4">Uploads</div>
        <MediaSelect class="col-3" v-bind:selection.sync="selection" label="Showing Uploads for:" v-if="$store.getters['auth/homePageFilter']" />
      </div>
      <div class="row q-gutter-xl justify-between">
        <q-btn @click="mode = 'create'; showModal = true" color="primary">Add New Upload</q-btn>
      </div>

      <div class="row full-width justify-end q-my-xl">
        <div v-if="uploads.length > 0" class="col-xs-12">
          <q-list sparse class=" fit no-scroll no-border">
            <q-item class="full-width q-px-none assets-cont" v-for="asset in uploads" :key="asset.uid">
              <q-item-section class="col-1">
                <div class="text-weight-light">
                  <q-btn @click="downloadFile(asset.path, asset.name)" icon="get_app" class="q-ma-sm" size="sm" />
                </div>
              </q-item-section>
              <q-item-section class="col-8">
                <div class="text-weight-medium">{{ asset.name }}</div>
              </q-item-section>
              <q-item-section class="col-1">
                <q-btn flat @click="selectForEdit(asset)">Edit</q-btn>
              </q-item-section>
              <q-item-section class="col-1 gt-sm">
                <q-btn flat @click="confirmDelete(asset)" :loading="(deleting === asset.uid)">Delete</q-btn>
              </q-item-section>
            </q-item>
          </q-list>
          <div class="row full-width justify-center q-mt-lg">
            <q-pagination v-if="pageMax > 1" v-model="page" :max="pageMax" :max-pages="10" :direction-links="true" />
          </div>
        </div>
        <div v-else class="col-xs-12">
          <div align="center">There are currently no uploads for this Location or User Group.</div>
        </div>
      </div>

      <q-dialog v-model="showModal" minimized>
        <q-card style="width: 560px" class="bg-lmsBackground primaryText-text">
          <q-form @submit="mode === 'create' ? addUpload() : saveUpload()" @reset="showModal = false" autocomplete="off">
            <q-card-section>
              <div class="text-h5">Upload File</div>
            </q-card-section>
            <q-card-section>
              <div v-if="mode === 'create'" class="col-xs-12 q-pa-lg">
                <input id="fileUpload" @change="uploadFile()" accept="file_extension|audio/*|video/*|image/*|media_type" type="file">
              </div>
              <div class="col-xs-12 q-mb-lg">
                  <q-input label="File Name" type="text" v-model.trim="upload.name" :rules="formRules.name" />
              </div>
              <div class="col-xs-12 q-mb-lg" v-if="$store.getters['auth/homePageFilter']">
                <MediaSelect v-bind:selection.sync="upload.assigned_uid" class="full-width" label="Assign To" />
              </div>
              <div class="col-xs-12 q-mb-lg">
                <q-input @click="$refs.qPublishDateProxy.show()" mask="##/##/####" fill-mask filled label="Publish Date" v-model="upload.publish_at" :rules="formRules.pubDate" >
                  <template v-slot:prepend>
                    <q-icon name="event" class="cursor-pointer primaryText-text">
                      <q-popup-proxy ref="qPublishDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date today-btn mask="MM/DD/YYYY" v-model="upload.publish_at" @input="$refs.qPublishDateProxy.hide()" class="bg-lmsBackground primaryText-text" />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
              <div class="col-xs-12 q-mb-lg">
                <q-input @click="$refs.qExpiresDateProxy.show()" mask="##/##/####" fill-mask filled label="Expiration Date" v-model="upload.expires_at">
                  <template v-slot:prepend>
                    <q-icon name="event" class="cursor-pointer primaryText-text">
                      <q-popup-proxy ref="qExpiresDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date :options="expirationDateOptions" today-btn mask="MM/DD/YYYY" v-model="upload.expires_at" @input="$refs.qExpiresDateProxy.hide()" class="bg-lmsBackground primaryText-text" />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
            </q-card-section>
            <div class="row justify-end gutter-xs q-my-lg q-mx-lg">
              <div class="col-auto q-mt-md self-center">
                <q-btn type="reset" flat class="q-btn-flat q-mx-sm">Cancel</q-btn>
                <q-btn type="submit" :loading="saving" color="primary">{{ mode === 'create' ? 'Upload' : 'Save' }}</q-btn>
              </div>
            </div>
          </q-form>
        </q-card>
      </q-dialog>

    </div>
  </div>
</template>
<script>
import { date } from 'quasar'
import MediaSelect from 'components/MediaSelect'

export default {
  components: { MediaSelect },
  data () {
    return {
      mode: 'create',
      saving: false,
      deleting: false,
      showModal: false,

      upload: {
        name: null,
        publish_at: date.formatDate(new Date(), 'MM/DD/YYYY hh:mm a'),
        expires_at: date.formatDate(date.addToDate(new Date(), { days: 30 }), 'MM/DD/YYYY hh:mm a'),
        path: null,
        file: null,
        assigned_uid: 'Company'
      },
      selection: null,
      page: 1,
      perPage: 10
    }
  },
  computed: {
    formRules () {
      return {
        name: [ this.$formRules.required('File Name') ],
        pubDate: [ this.$formRules.required('Publish Date') ]
      }
    },
    filteredUploads () {
      return this.$store.getters['uploads/getByAssignedUid'](this.selection)
    },
    pageMax () {
      if (this.filteredUploads.length === 0) return 0
      return Math.ceil(this.filteredUploads.length / this.perPage)
    },
    uploads () {
      let startIndex = (this.page - 1) * this.perPage
      return this.filteredUploads.slice(startIndex, startIndex + this.perPage)
    }
  },
  watch: {
    'upload.publish_at' (val) {
      this.upload.publish_at = date.formatDate(new Date(val), 'MM/DD/YYYY hh:mm a')
    },
    'upload.expires_at' (val) {
      this.upload.expires_at = date.formatDate(new Date(val), 'MM/DD/YYYY hh:mm a')
    },
    'showModal' (val) {
      if (!val) {
        this.upload = {
          name: null,
          publish_at: date.formatDate(new Date(), 'MM/DD/YYYY hh:mm a'),
          expires_at: date.formatDate(date.addToDate(new Date(), { days: 30 }), 'MM/DD/YYYY hh:mm a'),
          path: null,
          file: null,
          assigned_uid: this.$isAllowed(['||', 'master-admin', 'site-admin'], true) ? 'Company' : this.$store.getters['auth/customer']
        }
      }
    }
  },
  methods: {
    expirationDateOptions (qDate) {
      return qDate >= date.formatDate(new Date(this.upload.publish_at), 'YYYY/MM/DD')
    },
    selectForEdit ({ ...upload }) {
      this.mode = 'edit'
      this.upload = upload
      this.showModal = true
    },
    uploadFile () {
      this.upload.file = document.querySelector('#fileUpload').files[0]
    },
    addUpload () {
      if (!this.upload.file) {
        this.$failedValidationMsg('Please add a file')
        return
      }
      this.saving = true
      this.$store.dispatch('uploads/add', this.upload).then(() => {
        this.saving = false
        this.showModal = false
        this.$successMsg()
      }).catch((e) => {
        this.saving = false
        this.$failureMsg()
      })
    },
    saveUpload () {
      this.saving = true
      this.$store.dispatch('uploads/update', this.upload).then(() => {
        this.saving = false
        this.showModal = false
        this.$successMsg()
      }).catch((e) => {
        this.saving = false
        this.$failureMsg()
      })
    },
    confirmDelete (upload) {
      this.$q.dialog({
        title: 'Confirm Delete',
        message: `Are you sure you want to permantently delete '${upload.name}'?`,
        ok: 'Delete',
        cancel: 'Cancel'
      }).onOk(() => {
        this.deleting = upload.uid
        this.$store.dispatch('uploads/remove', upload.uid).then(() => {
          this.$successMsg()
          this.deleting = false
        }).catch((e) => {
          this.$failureMsg()
          this.deleting = false
        })
      })
    },
    downloadFile (filePath, fileName) {
      const link = document.createElement('a')
      link.download = fileName
      link.href = filePath
      link.click()
    }
  },
  created () {
    this.$store.dispatch('uploads/fetch')
    this.selection = this.$isAllowed(['||', 'master-admin', 'site-admin'], true) ? 'Company' : this.$store.getters['auth/customer']
    this.upload.assigned_uid = this.$isAllowed(['||', 'master-admin', 'site-admin'], true) ? 'Company' : this.$store.getters['auth/customer']
  }
}
</script>
